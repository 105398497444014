import Card from "components/common/Card/Card";
import PlotlyComponent from "components/common/Charts/Plotly";

export default function MTDLoadFactor() {
  const data = {
    data: [
      {
        hovertemplate: "%{x}, %{y}<extra></extra>",
        marker: {
          color: "#7696FF",
        },
        name: "XX",
        showlegend: true,
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        y: [0, 1905, 318, 0, 0, 0, 0],
        type: "bar",
      },
      {
        hovertemplate: "%{x}, %{y}<extra></extra>",
        marker: {
          color: "#B78EE5",
        },
        name: "YY",
        showlegend: true,
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        y: [280, 0, 911, 3198, 174, 417, 1640],
        type: "bar",
      },
    ],
    layout: {
      xaxis: {
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridcolor: "rgb(248, 235, 247)",
        zeroline: false,
        showgrid: false,
        categoryorder: "array",
        categoryarray: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yaxis: {
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridcolor: "rgb(248, 235, 247)",
        zeroline: false,
        showgrid: false,
      },
      title: {
        font: {
          family: "Open Sans",
          size: 23,
        },
        x: 0.5,
      },
      legend: {
        font: {
          color: "rgb(174, 181, 191)",
          size: 12,
          family: "Open Sans",
        },
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
            size: 12,
          },
          text: "Carriers",
          side: "top",
        },
        borderwidth: 0,
        bgcolor: "rgb(32, 32, 68)",
        orientation: "v",
        traceorder: "normal",
        itemsizing: "trace",
        itemclick: "toggle",
        itemdoubleclick: "toggleothers",
        xanchor: "left",
        yanchor: "auto",
        valign: "middle",
      },
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 20,
        pad: 4,
      },
      barmode: "group",
      paper_bgcolor: "rgb(32, 32, 68)",
      plot_bgcolor: "rgb(32, 32, 68)",
      autosize: true,
      id: "aa8ae163-b23f-444b-9a9b-cbb572bc7d94",
    },
  };
  return (
    <Card variant="secondary" title="MTD Load Factor - TY vs LY" width={"100%"}>
      <Card variant="primary">
        <PlotlyComponent
          data={data.data}
          layout={data.layout}
          minHeight={524}
        />
      </Card>
    </Card>
  );
}
