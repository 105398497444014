import { RestClient } from "lib/api/RestClient";

export class FareStructureFilterOptionsClient {
  private static _client: RestClient;
  static fetchFareStructureFilterOptions({ params }) {
    return FareStructureFilterOptionsClient.getClient().getCall(
      `/api/msdv2/fare-structure/structure-filters?${new URLSearchParams(
        params
      )}`
    );
  }
  static getClient() {
    if (FareStructureFilterOptionsClient._client == null) {
      FareStructureFilterOptionsClient._client = new RestClient();
    }
    return FareStructureFilterOptionsClient._client;
  }
}
